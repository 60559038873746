<template>
    <div class='userMeans'>
        <el-dialog class="zc_dialog_box" :visible.sync="dialogVisible" width="680px" :close-on-click-modal='false'>
            <div slot="title">
                <div class="name">查看资料</div>
            </div>
            <div class="details_info">
                <div class="info_box">
                    <div class="info_item" v-for="(item, index) in infoList" :key="index">
                        <div class="label">{{ item.label }}</div>
                        <div class="value">{{ item.value }}</div>
                    </div>
                </div>
                <div class="img_box">
                    <div class="img_box_head">
                        <div class="txt">身份证照片</div>
                    </div>
                    <div class="img_box_con">
                        <div class="img" @click="handleImgPreview(info.memberAgency ? info.memberAgency.idcard_front : '')">
                            <img :src="info.memberAgency ? info.memberAgency.idcard_front : ''" alt="">
                        </div>
                        <div class="img" @click="handleImgPreview(info.memberAgency ? info.memberAgency.idcard_reverse : '')">
                            <img :src="info.memberAgency ? info.memberAgency.idcard_reverse : ''" alt="">
                        </div>
                    </div>
                </div>
                <div class="img_box" v-if="info.corp_id > 0">
                    <div class="img_box_head">
                        <div class="txt">营业执照</div>
                    </div>
                    <div class="img_box_con">
                        <div class="img" @click="handleImgPreview(info.memberAgency ? info.memberAgency.business_license : '')">
                            <img :src="info.memberAgency ? info.memberAgency.business_license : ''" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!-- 图片预览 -->
        <el-dialog :visible.sync="imgPreview" :close-on-click-modal='false'>
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>

export default {
    components: {},
    props: {},
    data() {
        return {
            dialogVisible: false,
            id: '',
            info: {},
            loading: false,
            imgPreview: false,
            dialogImageUrl: '',
        }
    },
    computed: {
        infoList() {
            if (JSON.stringify(this.info) == "{}") {
                return []
            } else {
                let infoList = [
                    {
                        label: '用户ID：',
                        value: this.info.id
                    },
                    {
                        label: '用户名称：',
                        value: this.info.nickname
                    },
                    {
                        label: '身份：',
                        value: this.info.corp_id > 0 ? '企业' : '个人'
                    },
                    {
                        label: '登录手机号：',
                        value: this.info.mobile
                    },
                    {
                        label: '注册来源：',
                        value: this.info.source_name
                    },
                    {
                        label: '推荐人：',
                        value: this.info.referrer_mobile || '无'
                    },
                    {
                        label: '所属代理商：',
                        value: this.info.parent_agency_mobile
                    },
                    {
                        label: '订阅套餐类型：',
                        value: this.info.subscribe_name
                    },
                    {
                        label: '订阅有效期：',
                        value: this.info.subscribe_started && this.info.subscribe_ended ? `${this.info.subscribe_started}/${this.info.subscribe_ended}` : ''
                    },
                    {
                        label: '钻石余额：',
                        value: this.info.diamond
                    },
                    {
                        label: '钻石总消耗：',
                        value: this.info.diamond_con
                    },
                    {
                        label: '时长余额：',
                        value: this.$times.formatSeconds(this.info.video_duration || 0, 1)
                    },
                    {
                        label: '剩余次数：',
                        value: this.info.drawing
                    },
                    {
                        label: '购买模型个数：',
                        value: this.info.buy_mo
                    },
                    {
                        label: '定制模型个数：',
                        value: this.info.customize_mo
                    },
                    {
                        label: '作品总数：',
                        value: this.info.total_works_num
                    },
                    {
                        label: '平均作品时长：',
                        value: this.info.avg_works_time
                    },
                    {
                        label: '注册时间：',
                        value: this.info.create_time
                    },
                    {
                        label: '绑定代理时间：',
                        value: this.info.agency_time
                    },
                    {
                        label: '联系人：',
                        value: this.info?.memberAgency?.contact || ''
                    },
                ]
                return infoList
            }
        }
    },
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn(id) {
            this.dialogVisible = true
            this.id = id
            this.getDetails()
        },
        getDetails() {
            this.loading = true
            this.$userApi.getMemberDetails({ id: this.id }).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.info = res.result
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 图片预览
        handleImgPreview(url) {
            this.dialogImageUrl = url;
            this.imgPreview = true;
        },
    },
}
</script>

<style lang='scss' scoped>
.userMeans {
    .details_info {
        .info_box {
            display: flex;
            flex-wrap: wrap;

            .info_item {
                display: flex;
                width: 50%;
                margin-bottom: 20px;

                .label {
                    width: 98px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }

                .value {
                    flex: 1;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
            }
        }

        .img_box {
            width: 640px;
            // height: 222px;
            border-radius: 2px;
            border: 1px solid #DCDCDC;
            margin-bottom: 20px;

            .img_box_head {
                height: 42px;
                background: #F0F2F5;
                border-radius: 2px;
                padding: 0 20px;

                .txt {
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 42px;
                }
            }

            .img_box_con {
                display: flex;
                padding: 20px;

                .img {
                    width: 220px;
                    height: 140px;
                    margin-right: 20px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
</style>
