<!-- @Author: Yu_Bo -->
<template>
    <div class=''>
        <div class="table_wrap">
            <el-table ref="table1" key="table1" :data="list" v-loading="loading" @sort-change="onSortChange"
                @selection-change="handleSelectionChange" style="width: 100%" header-cell-class-name="table-header"
                cell-class-name="cell-class">
                <template slot="empty">
                    <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                </template>
                <el-table-column type="selection" width="55" fixed="left">
                </el-table-column>
                <el-table-column prop="id" label="用户ID" fixed="left">
                </el-table-column>
                <el-table-column prop="mobile" label="登录手机账号" width="125" fixed="left">
                </el-table-column>
                <el-table-column prop="data3" label="用户名称" width="145" fixed="left">
                    <template slot-scope="scope">
                        <div class="flex_box">
                            <div v-if="scope.row.corp_id == 0" class="isPerson">个人</div>
                            <div v-if="scope.row.corp_id > 0" class="isCompony">企业</div>
                            <div class="txt_wrap">{{ scope.row.nickname }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="source_name" label="注册来源">
                </el-table-column>
                <el-table-column prop="parent_agency_mobile" label="所属代理商" width="125">
                </el-table-column>
                <el-table-column prop="data5" label="订阅套餐类型" width="125">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="subscribeDetailBtn(scope.row)">
                            {{ scope.row.subscribe_name }}
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="订阅有效期" width="125">
                    <template slot-scope="scope">
                        <div v-if="scope.row.subscribe_started && scope.row.subscribe_ended">{{ scope.row.subscribe_started
                        }}/{{ scope.row.subscribe_ended }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="diamond" label="钻石余额" width="125">
                </el-table-column>
                <el-table-column prop="diamond_con" label="钻石总消耗" width="125">
                </el-table-column>
                <el-table-column prop="video_duration_text" label="时长余额" width="125">
                </el-table-column>
                <el-table-column prop="major_cust" label="剩余次数" width="125">
                </el-table-column>
                <el-table-column prop="buy_mo" label="购买模型个数" width="125">
                </el-table-column>
                <el-table-column prop="customize_mo" label="定制模型个数" width="125">
                </el-table-column>
                <el-table-column prop="total_works_num" label="作品总数" width="125">
                </el-table-column>
                <el-table-column prop="avg_works_time" label="平均作品时长" width="125">
                </el-table-column>
                <el-table-column prop="create_time" label="账户注册时间" width="135" sortable="custom">
                </el-table-column>
                <el-table-column prop="agency_time" label="绑定代理时间" width="135" sortable="custom">
                </el-table-column>
                <el-table-column prop="" label="是否启用" width="125">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="2"
                            @change="statusEdit(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="200">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="recharge(scope.row.id)">
                            充值
                        </el-button>
                        <el-button type="text" size="small" @click="toDetail(scope.row.id)">
                            明细
                        </el-button>
                        <el-button type="text" size="small" @click="inviteCodeBtn(scope.row)">
                            邀请码
                        </el-button>
                        <el-button type="text" size="small" @click="userMeansBtn(scope.row.id)">
                            查看资料
                        </el-button>
                        <el-button type="text" size="small" v-if="scope.row.corp_id > 0" @click="activationBtn(scope.row)">
                            激活直播
                        </el-button>
                        <el-button type="text" size="small" @click="setAsLive(scope.row,scope.$index)">
                            {{ scope.row.is_live_admin==1?'取消模板管理员':'设为模板管理员' }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 充值 -->
        <recharge-popup :topup="topup" @getList="getList" ref="recharge"></recharge-popup>
    </div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import RechargePopup from '../rechargePopup/index.vue' //充值
export default {
    components: {
        EmptyData,
        RechargePopup,
    },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
        topup: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
        }
    },
    computed: {},
    watch: {},
    created() {
        // console.log(this.topup)
    },
    mounted() { },
    methods: {
        // 充值
        recharge(id) {
            this.$refs.recharge.open(id, 'user')
        },
        // 订阅详情
        subscribeDetailBtn(info) {
            this.$emit('subscribeDetailBtn', info)
        },
        // 邀请码
        inviteCodeBtn(info) {
            this.$emit('inviteCodeBtn', info)
        },
        // 用户资质
        userMeansBtn(id) {
            this.$emit('userMeansBtn', id)

        },
        //  修改列表
        getList() {
            this.$emit('getList', '')
        },
        // 状态修改
        statusEdit(info) {
            this.$emit('statusEdit', info)
        },
        // 列表排序
        onSortChange(column) {
            this.$emit('onSortChange', column)
        },
        // 列表筛选
        handleSelectionChange(val) {
            this.$emit('handleSelectionChange', val)
        },
        // 跳转详情页
        toDetail(id) {
            this.$router.push({
                path: '/user/detail',
                query: {
                    id: id
                }
            })
        },
        // 激活直播
        activationBtn(info) {
            this.$emit('activationBtn', info)
        },
        // 取消/设置直播管理员
        setAsLive(item,index){
            var that = this
            var params = {
                id: item.id,
                is_live_admin: item.is_live_admin==1?2:1
            }
            that.$userApi.setLiveAdmin(params).then(res => {
                if (res.code == 1000) {
                    that.$succMsg(res.message)
                    that.$emit('getList')
                }else{
                    that.$errMsg(res.message)
                }
            })
        },
    },
}
</script>

<style lang='scss' scoped>
@import "./index.scss";
</style>