<!-- @Author: Yu_Bo -->
<template>
    <div class='user_list'>
        <div class="vue_title">
            用户列表
        </div>
        <div class="main">
            <search-params ref="SearchParams" :is_phone="true" :is_name="true" :is_agancy_phone="true" :is_user_type="true"
                :user_type_list="user_type_list" :is_identity="true" :user_identity_list="user_identity_list"
                :is_time_field="true" :time_field_list="time_field_list" :is_export='true' @search="search"
                @export='exportBtn'></search-params>
            <table-list :list="list" :loading="loading" :topup="topup" @subscribeDetailBtn="subscribeDetailBtn"
                @inviteCodeBtn="inviteCodeBtn" @userMeansBtn="userMeansBtn" @statusEdit="statusEdit" @activationBtn="activationBtn"
                @onSortChange="onSortChange" @getList="getList" @handleSelectionChange="handleSelectionChange"></table-list>
            <div class="page_box pr20">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
                    @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
        <!-- 订阅详情 -->
        <subscribe-detail ref="SubscribeDetails"></subscribe-detail>
        <!-- 邀请码 -->
        <invite-code ref="InviteCode" @refreshList="getList"></invite-code>
        <!-- 用户资质 -->
        <user-means ref="UserMeans"></user-means>
        <!-- 激活直播 -->
        <activation-layer ref="ActivationLayer"></activation-layer>
    </div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import SearchParams from '../components/search/index.vue' // 筛选项
import TableList from '../components/tableList/userList.vue' // 列表
import SubscribeDetail from '../components/subscribeDetail/subscribeDetail.vue' // 订阅详情
import InviteCode from '../components/inviteCode/inviteCode.vue' // 邀请码
import UserMeans from '../components/means/userMeansInfo.vue' // 用户资质
import ActivationLayer from '../components/activation/index.vue' // 激活直播
export default {
    components: {
        EmptyData,
        PagingPage,
        SearchParams,
        TableList,
        SubscribeDetail,
        InviteCode,
        UserMeans,
        ActivationLayer,
    },
    props: {},
    data() {
        return {
            params: {},
            user_identity_list: [], // 用户身份类型
            user_type_list: [], // 用户类型
            time_field_list: [],
            list: [],
            page: 1,
            limit: 10,
            total: 0,
            loading: false,
            topup: [],
            ids: [],
        }
    },
    computed: {},
    watch: {},
    created() {
        // 获取充值参数
        this.getRechargeType()
        this.getParams()
        this.getList()
    },
    mounted() { },
    methods: {
        getParams() {
            this.$userApi.getMemberListParams().then(res => {
                if (res.code == 1000) {
                    this.time_field_list = res.result.time_type
                    this.user_type_list = res.result.member_type
                    this.user_identity_list = res.result.type
                }
            })
        },
        search(params) {
            this.params.mobile = params.phone
            this.params.keyword = params.name
            this.params.type = params.identity_type
            this.params.member_type = params.user_type
            this.params.agency_mobile = params.agancy_phone
            this.params.time_type = params.time_field
            this.params.time_period = params.date ? `${params.date[0]}|${params.date[1]}` : ''

            this.page = 1
            this.list = []
            this.total = 0
            this.getList()
        },
        resetFrom() {
            this.$refs.SearchParams.resetFrom()
            this.page = 1
            this.list = []
            this.total = 0
            this.params = {}
            this.getList()
        },
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList();
        },
        currentChangeBtn(val) {
            this.page = val;
            this.getList();
        },
        // 列表排序
        onSortChange(column) {
            if (column.order == 'ascending') this.params.order_redirect = 'asc'
            if (column.order == 'descending') this.params.order_redirect = 'desc'
            if (!column.order) this.params.order_redirect = ''
            this.params.order_field = column.prop
            this.getList()
        },
        getList() {
            const data = {
                page: this.page,
                limit: this.limit,
                ...this.params,
            }
            this.loading = true
            this.$userApi.getMemberList(data).then(res => {
                this.loading = false

                if (res.code == 1000) {
                    this.list = res.result.data
                    this.total = res.result.total
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 订阅详情
        subscribeDetailBtn(val) {
            this.$refs.SubscribeDetails.openDialogBtn(val)
        },
        // 邀请码
        inviteCodeBtn(val) {
            this.$refs.InviteCode.openDialogBtn(val)
        },
        // 用户资质
        userMeansBtn(val) {
            this.$refs.UserMeans.openDialogBtn(val)
        },
        // 状态修改
        statusEdit(info) {
            const data = {
                id: info.id,
                status: info.status
            }
            // console.log(data)
            this.$userApi.memberStatusEdit(data).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.getList()
                } else {
                    this.$errMsg(res.message)

                }
            })
        },
        // 获取套餐
        getRechargeType() {
            this.$userApi.rechargeType().then(res => {
                if (res.code == 1000) {
                    let arr = res.result.topup
                    arr.forEach(ele => {
                        this.topup.push({
                            value: ele.value,
                            label: ele.label.name,
                        })
                    })
                }
            })
        },
        // 导出
        exportBtn(params) {
            var that = this
            var obj = {
                ...that.params,
                ids: this.ids.length ? this.ids.join(',') : '',
            }
            that.$userApi.memberExport(obj).then(res => {
                return
            })
        },
        // 列表勾选
        handleSelectionChange(val) {
            this.ids = val.map(item => item.id)
        },
        // 激活直播
        activationBtn(info) {
            // console.log(info)
            this.$refs.ActivationLayer.openDialogBtn(info)
        }
    },
}
</script>

<style lang='scss' scoped>
@import "./index.scss";

.user_list {
    height: calc(100vh - 270px);
}</style>