<template>
    <div class="activation zc_dialog_box">
        <el-dialog :visible.sync="dialogVisible" width="1000px" :close-on-click-modal='false'>
            <div slot="title">
                <div class="name">激活直播</div>
            </div>
            <div class="inv_main scroll_bar_x">
                <el-table ref="multipleTable" :data="tableData" v-loading="loading"
                    @selection-change="handleSelectionChange" :header-cell-style="headerCellStyle" style="width: 100%">
                    <el-table-column type="selection" width="55"> </el-table-column>
                    <el-table-column prop="name" label="企业名称"></el-table-column>
                    <el-table-column prop="" label="状态" width="105">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="2"
                                @change="statusEdit(scope.row)">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="直播有效期">
                        <template slot-scope="scope">
                            <el-date-picker style="width:100%" size='small' :key="itemKey" v-model="scope.row.time"
                                @input="$event => timeChange($event, scope.row)" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :clearable="true">
                            </el-date-picker>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div slot="footer">
                <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
                <el-button class="btnBgColor_blue" size="small" type="primary" :disabled="!multipleSelection.length"
                    @click="affirmBtn" :loading="subLoading">确
                    定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dialogVisible: false,
            subLoading: false,
            info: {},
            tableData: [
                // {
                //     id: 1,
                //     name: '云川',
                //     status: 1,
                //     time: ['2023-06-10', '2023-06-10']
                // },
                // {
                //     id: 2,
                //     name: '云川2',
                //     status: 1,
                //     time: ['2023-06-10', '2023-06-10']
                // },
            ],
            headerCellStyle: {
                background: '#F0F2F5',
                color: '#333',
                fontWeight: 500,
                fontSize: '14px'
            },
            loading: false,
            multipleSelection: [],
            selectArr: [],
            itemKey: '',
        }
    },
    methods: {
        // 打开弹框
        openDialogBtn(info) {
            this.dialogVisible = true
            this.info = info
            // this.toggleSelection(this.selectArr)
            this.getMemberCorps(info.id)
        },

        // 取消
        cancelBtn() {
            this.dialogVisible = false
        },
        // 确定
        affirmBtn() {
            // console.log(this.multipleSelection)
            const data = {
                data: this.multipleSelection
            }
            this.subLoading = true
            this.$userApi.memberOpenLive(data).then(res => {
                this.subLoading = false
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.cancelBtn()
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 用户所关联的企业
        getMemberCorps(id) {
            this.$userApi.memberCorps(id).then(res => {
                if (res.code == 1000) {
                    this.tableData = res.result
                    if (this.tableData && this.tableData.length) {
                        this.tableData.forEach(item => {
                            if (item.live_start && item.live_end) {
                                item.time = [item.live_start, item.live_end]
                            } else {
                                // console.log(111)
                                item.time = []
                            }
                        })
                    }
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 勾选
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 数据回显
        toggleSelection(rows) {
            if (rows) {
                this.tableData.forEach(item => {
                    rows.forEach(row => {
                        if (item.id == row) {
                            this.$nextTick(() => {
                                this.$refs.multipleTable.toggleRowSelection(item, true);
                            })
                        }
                    });
                })
            }
        },
        // 状态修改
        statusEdit(row) {
            this.multipleSelection.forEach(item => {
                if (item.id == row.id) {
                    item.status = row.status
                }
            })
        },
        // 日期修改
        timeChange(val, row) {
            console.log(val)
            this.itemKey = Math.random()
            this.tableData.forEach(item => {
                if (item.id == row.id) {
                    item.time = val
                    if (val) {
                        item.live_start = val[0]
                        item.live_end = val[1]
                    } else {
                        item.live_start = null
                        item.live_end = null
                    }
                }
            })
            this.multipleSelection.forEach(item => {
                if (item.id == row.id) {
                    item.time = val
                    if (val) {
                        item.live_start = val[0]
                        item.live_end = val[1]
                    } else {
                        item.live_start = null
                        item.live_end = null
                    }
                }
            })
        },

    }
}
</script>
<style lang="scss" scoped>
.activation {
    .inv_main {
        width: 100%;
        max-height: 500px;
        overflow-y: auto;

    }
}
</style>