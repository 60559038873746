<template>
    <div class='inviteCode zc_dialog_box'>
        <el-dialog :visible.sync="dialogVisible" width="550px" :close-on-click-modal='false'>
            <div slot="title">
                <div class="name">邀请码</div>
            </div>
            <div class="inv_main">
                <div class="main_text">
                    <div class="left">
                        <!-- <span>*</span> -->
                        邀请码个数</div>
                    <div class="right">
                        <el-input type="number" v-model.number="info.code_num" placeholder="请输入邀请码数量"></el-input>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn" :loading="subLoading">确
                    定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    components: {},
    props: {},
    data() {
        return {
            dialogVisible: false,
            subLoading: false,
            id: '',
            code_num: '',
            info: {}
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn(info) {
            this.dialogVisible = true
            this.info = info
        },
        // 取消
        cancelBtn() {
            this.dialogVisible = false
        },
        // 确定
        affirmBtn() {
            if (!this.info.code_num) {
                this.$errMsg('请输入邀请码数量')
                return
            }

            const data = {
                id: this.info.id,
                code_num: this.info.code_num,
            }
            this.subLoading = true
            this.$userApi.memberInvit(data).then(res => {
                this.subLoading = false
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.cancelBtn()
                    this.$emit('refreshList')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
    },
}
</script>

<style lang='scss' scoped>
.inviteCode {
    .inv_main {
        width: 100%;
        padding: 10px 30px 0;

        .main_text {
            width: 100%;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                width: 70px;
                text-align: right;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;

                span {
                    padding-right: 5px;
                    color: #E00C25;
                }
            }

            .right {
                width: 360px;

                .el-input {
                    width: 100%;
                }
            }
        }
    }
}
</style>
